import Login from "./Components/LoginForm/Login";

function App() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default App;
